import StaffService from 'services/staffService'
import AgencyService from '../../agenciesService'

export enum ContactType {
  Agent,
  Staff,
}
interface SendInvitationEmailRequest {
  id: string
  contactType: ContactType
  getAuthToken?: () => Promise<string | void>
}

const SendInvitationEmail = async (
  requestBody: SendInvitationEmailRequest
): Promise<number | void> => {
  const { id, contactType, getAuthToken } = requestBody
  if (!getAuthToken) {
    return
  }
  const token = await getAuthToken()

  if (contactType === ContactType.Agent) {
    const service = new AgencyService(token || '')

    if (!service) return

    const result = await service.agencyService.post('SendInvitationEmail', {
      AgentId: id,
    })

    return result.status
  } else if (contactType === ContactType.Staff) {
    const service = new StaffService(token || '')

    if (!service) return

    const result = await service.staffService.post('SendInvitationEmail', {
      StaffMemberId: id,
    })

    return result.status
  }
}

export default SendInvitationEmail

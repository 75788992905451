import { useContext, useEffect, useMemo, useState } from 'react'
import { MenuTitles } from '../../constants/menu'
import { AgencyContext, UserContext } from 'contexts'
import { ContentLayout, PageLayout } from 'layout'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import isEqualWith from 'lodash/isEqualWith'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import {
  AgxTextInput,
  AgxDatePicker,
  AgxColumn,
  AgxRow,
  AgxLabel,
  AgxButton,
  AgxToast,
  AgxSelect,
  AgxTextArea,
  AustralianStateNumericMap,
  AustralianState,
  AgxMultiSelect,
  Option,
  StateAgreementFormTypeMap,
  StateAgreementFormType,
  AgxPhoneNumberInput,
  Images,
} from '@urbanx/agx-ui-components'
import { Agency, AgencyDetails, LicenceDetails } from 'types/agency'
import { GetAllAgencies } from 'services'
import { GetAgencyDetails, UpdateAgency } from 'services'
import { AgxToastState } from 'types/commonTypes'
import { useAzureAuth } from 'hooks/useAzureAuth'
import { FormPrompt } from 'components/FormPrompt'
import { isDirtyCompare } from 'utils/compares'
import './agencyDetails.css'

const agencyStateOptions: Option[] = [
  { value: AustralianStateNumericMap.ACT.toString(), label: 'ACT' },
  { value: AustralianStateNumericMap.NSW.toString(), label: 'NSW' },
  { value: AustralianStateNumericMap.QLD.toString(), label: 'QLD' },
  { value: AustralianStateNumericMap.NT.toString(), label: 'NT' },
  { value: AustralianStateNumericMap.SA.toString(), label: 'SA' },
  { value: AustralianStateNumericMap.Tas.toString(), label: 'TAS' },
  { value: AustralianStateNumericMap.Vic.toString(), label: 'VIC' },
  { value: AustralianStateNumericMap.WA.toString(), label: 'WA' },
]

const allStateOptions: Option[] = Object.values(AustralianState).map(
  (state) => {
    return {
      value: state.toString(),
      label: state.toString(),
    }
  }
)

const qldAgreementOptions: Option[] = [
  {
    value: StateAgreementFormType.QLDResidentialForm6.toString(),
    label: StateAgreementFormTypeMap.QLDResidentialForm6,
  },
]

const nswAgreementOptions: Option[] = [
  {
    value: StateAgreementFormType.NSWResidentialAgreement.toString(),
    label: StateAgreementFormTypeMap.NSWResidentialAgreement,
  },
  {
    value: StateAgreementFormType.NSWRuralAgreement.toString(),
    label: StateAgreementFormTypeMap.NSWRuralAgreement,
  },
]

const getAgreementOptions = (companyState: string | number) => {
  switch (companyState) {
    case AustralianStateNumericMap.QLD.toString():
    case AustralianStateNumericMap.QLD: {
      return qldAgreementOptions
    }
    case AustralianStateNumericMap.NSW.toString():
    case AustralianStateNumericMap.NSW: {
      return nswAgreementOptions
    }
    default:
      return []
  }
}

const AgencyDetailsPage = () => {
  const user = useContext(UserContext)
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [formLoaded, setFormLoaded] = useState(false)
  const [selectedAgency, setSelectedAgency] = useContext(AgencyContext)
  const { agencyId } = useParams()
  const queryClient = useQueryClient()
  const [companyLicences, setCompanyLicences] = useState<LicenceDetails[]>([])
  const [toastState, updateToastState] = useState<AgxToastState>({
    color: 'success',
    message: '',
    open: false,
  })

  const [, getAuthToken] = useAzureAuth()

  const { data: agencies, refetch: refetchAllAgencies } = useQuery<
    Agency[] | undefined
  >({
    queryKey: ['all-agencies'],
    queryFn: () => GetAllAgencies(getAuthToken),
  })

  const [agencyInformation, setAgencyInformation] = useState<AgencyDetails>(
    new AgencyDetails()
  )

  const {
    data: agencyDetails,
    isFetched,
    isLoading,
  } = useQuery<AgencyDetails | undefined>({
    queryKey: ['getAgencyDetails', agencyId],
    queryFn: (queryKey) => GetAgencyDetails(queryKey, getAuthToken),
    enabled: !!agencyId,
  })

  const { mutate: updateAgencyDetails } = useMutation(UpdateAgency, {
    onSuccess: () => {
      setIsFormDirty(false)
      refetchAllAgencies()
      queryClient.invalidateQueries({
        queryKey: ['getAgencyDetails', agencyId],
      })
      //setSelectedAgency()
      updateToastState({
        color: 'success',
        message: `${MenuTitles.AGENCY_DETAILS} updated successfully`,
        open: true,
      })
    },
    onError: () => {
      updateToastState({
        color: 'error',
        message: `Error updating ${MenuTitles.AGENCY_DETAILS}`,
        open: true,
      })
    },
  })

  const getNextAvailableState = () => {
    const allStates = Object.values(AustralianStateNumericMap)
      .filter((value) => typeof value === 'number')
      .map((i) => i.toString())

    const nextState = allStates.find(
      (i) => !companyLicences.map((cl) => cl.state).includes(i)
    )

    return nextState
  }

  const addLicence = () => {
    const nextState = getNextAvailableState()
    const newLicence: LicenceDetails = {
      licenceNumber: '',
      uuid: uuidv4(),
      state: nextState,
      expiryDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
    }
    setCompanyLicences([...companyLicences, newLicence])
  }

  const removeLicence = (uuid: string) => {
    const updatedLicences = companyLicences.filter((l) => l.uuid !== uuid)
    setCompanyLicences(updatedLicences)
  }

  const updateCompanyLicences = (licence: LicenceDetails) => {
    if (companyLicences) {
      const updatedLicences = [
        ...companyLicences.map((l) => (l.uuid === licence.uuid ? licence : l)),
      ]
      setCompanyLicences(updatedLicences)

      setAgencyInformation((previousState: AgencyDetails) => ({
        ...previousState,
        companyDetails: {
          ...previousState.companyDetails,
          licences: updatedLicences,
        },
      }))
    }
  }

  useEffect(() => {
    setSelectedAgency(agencies?.find((a) => a.id === agencyId))
  }, [agencies, agencyId])

  useEffect(() => {
    if (isFetched && agencyDetails) {
      setAgencyInformation(agencyDetails)
      if (agencyDetails.companyDetails.licences) {
        setCompanyLicences(
          agencyDetails.companyDetails.licences.map((licence) => ({
            ...licence,
            uuid: uuidv4(),
          }))
        )
      }

      setIsFormDirty(false)

      if (agencyDetails.id != null) setFormLoaded(true)
    }
  }, [agencyDetails])

  useEffect(() => {
    setIsFormDirty(
      !isEqualWith(agencyDetails, agencyInformation, isDirtyCompare)
    )
  }, [agencyInformation, agencyDetails])

  const addressStateDefaultValue = useMemo(() => {
    const selectedOption = allStateOptions.find(
      (option) => option.value === agencyInformation?.address?.state
    )
    return selectedOption ?? allStateOptions[0]
  }, [agencyInformation?.address?.state])

  if (agencyInformation == null) return null

  const {
    tradingName,
    companyDetails: { licenseeName, abn, acn } = {},
    listingsEmail,
    listingsPhone,
    address: { streetAddress, suburb, postalCode } = {},
    instructionsConditions,
  } = agencyInformation

  const sanatizeAgencyDetails = (agencyInformation: AgencyDetails) => {
    const updatedLicences = companyLicences.map((licence) => {
      const { uuid: _uuid, ...rest } = licence
      return { ...rest }
    })

    return {
      ...agencyInformation,
      companyDetails: {
        ...agencyInformation.companyDetails,
        licences: updatedLicences,
      },
    }
  }

  const saveChanges = () => {
    updateAgencyDetails({
      getAuthToken,
      ...sanatizeAgencyDetails(agencyInformation),
    })
  }

  if (!isFetched || isLoading || !formLoaded) return null

  return (
    <PageLayout
      agentName={user?.firstName || ''}
      agencyName={selectedAgency?.name || ''}
      currentPageTitle={MenuTitles.AGENCY_DETAILS}
    >
      <ContentLayout hasSideMenu={true} activeMenu={MenuTitles.AGENCY_DETAILS}>
        <FormPrompt hasUnsavedChanges={isFormDirty} />
        <AgxToast selector="#agxToast" toastState={toastState} />
        <AgxColumn veryLargeGap>
          <AgxRow spaceBetween centered extraClasses="borderBottomContainer">
            <AgxLabel large>{MenuTitles.AGENCY_DETAILS}</AgxLabel>
            <AgxButton
              text="Save Changes"
              medium
              primary
              onClick={saveChanges}
            />
          </AgxRow>

          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Company details</AgxLabel>
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="tradingName"
                label="Trading Name"
                stretch
                noOptionalLabel
                defaultValue={tradingName || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    tradingName: value,
                  }))
                }
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="abn"
                label="ABN"
                abn={true}
                stretch
                noOptionalLabel
                defaultValue={abn || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      abn: value,
                    },
                  }))
                }
              />

              <AgxTextInput
                id="acn"
                label="ACN"
                acn={true}
                stretch
                noOptionalLabel
                defaultValue={acn || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      acn: value,
                    },
                  }))
                }
              />
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="licenseeName"
                label="Licensee name"
                stretch
                noOptionalLabel
                defaultValue={licenseeName || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    companyDetails: {
                      ...previousState.companyDetails,
                      licenseeName: value,
                    },
                  }))
                }
              />
            </AgxRow>
            {companyLicences?.map((licence, index) => {
              const agreementOptions = getAgreementOptions(
                licence.state as string
              )

              return (
                <>
                  <AgxRow extraClasses="borderBottomContainer" spaceBetween>
                    <AgxLabel medium>{`Licence ${index + 1}`}</AgxLabel>
                    {companyLicences.length > 1 && (
                      <AgxButton
                        text={'Remove'}
                        small
                        hollow
                        onClick={() => removeLicence(licence.uuid ?? '')}
                      />
                    )}
                  </AgxRow>
                  <AgxRow veryLargeGap>
                    <AgxTextInput
                      id="licenceNumber"
                      label="Licence Number"
                      stretch
                      noOptionalLabel
                      parentControlValue={true}
                      defaultValue={licence.licenceNumber || ''}
                      onInputValueChange={({ value }: { value: string }) =>
                        updateCompanyLicences({
                          licenceNumber: value,
                          expiryDate: licence.expiryDate,
                          state: licence.state,
                          agreements: licence.agreements,
                          uuid: licence.uuid,
                        })
                      }
                    />

                    <AgxDatePicker
                      id="dtLicenseeExpirtDate"
                      date
                      label="Expiry Date"
                      noOptionalLabel
                      stretch
                      defaultValue={licence.expiryDate}
                      onValueChanged={({ value }: { value: string }) => {
                        updateCompanyLicences({
                          licenceNumber: licence.licenceNumber,
                          expiryDate:
                            value === 'Invalid date' || value === ''
                              ? null
                              : moment(value).format('YYYY-MM-DDTHH:mm:ss'),
                          state: licence.state,
                          agreements: licence.agreements,
                          uuid: licence.uuid,
                        })
                      }}
                    />
                  </AgxRow>
                  <AgxRow veryLargeGap extraClasses="checkboxContainer">
                    {/*
               TODO: Enable Multi-select state dropdown when we have to introduce the functionality to the agents
               As per the new requirements in the card https://urbanx-development.atlassian.net/browse/AG-2272
               <AgxMultiSelect
                 id="companyState"
                 label="State"
                 hideOptionalLabel
                 defaultValue={companyStates}
                 onValueChanged={({ value }: { value: string }) => {
                   setCompanyStates(!value ? undefined : value?.split(','))
                 }}
                 options={stateOptions}
               /> */}
                    <AgxSelect
                      id="companyState"
                      label="State"
                      extraClasses="companyState"
                      options={agencyStateOptions.map((option) =>
                        companyLicences.some(
                          (l) => l.state?.toString() === option.value
                        )
                          ? { ...option, disabled: true }
                          : option
                      )}
                      defaultValue={{
                        label: licence.state
                          ? AustralianStateNumericMap[
                              parseInt(licence.state) ?? 0
                            ]
                          : AustralianStateNumericMap[0],
                        value: licence.state
                          ? licence.state
                          : AustralianStateNumericMap.ACT.toString(),
                      }}
                      hideOptionalLabel
                      onValueChanged={({ value }: { value: string }) => {
                        updateCompanyLicences({
                          licenceNumber: licence.licenceNumber,
                          expiryDate: licence.expiryDate,
                          state: value,
                          agreements: licence.agreements,
                          uuid: licence.uuid,
                        })
                      }}
                    />
                    <AgxMultiSelect
                      id="agreements"
                      label="Agreements"
                      options={agreementOptions}
                      defaultValue={agreementOptions.reduce(
                        (acc: string[], curr: Option) => {
                          const ag = licence.agreements?.find(
                            (t: any) => t === Number(curr.value)
                          )
                          if (ag !== undefined) {
                            acc.push(ag.toString())
                          }
                          return acc
                        },
                        []
                      )}
                      hideOptionalLabel
                      onValueChanged={({ value }: { value: string[] }) => {
                        if (value.length !== licence?.agreements?.length)
                          updateCompanyLicences({
                            licenceNumber: licence.licenceNumber,
                            expiryDate: licence.expiryDate,
                            state: licence.state,
                            agreements: value?.map((v) => parseInt(v)) ?? [],
                            uuid: licence.uuid,
                          })
                      }}
                    />
                  </AgxRow>
                </>
              )
            })}
            <AgxRow>
              <AgxButton
                text={'Add Licence'}
                primary
                medium
                disabled={companyLicences.length === 8}
                onClick={() => addLicence()}
                rightIcon={<Images.Plus />}
              />
            </AgxRow>
          </AgxColumn>
          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Contact details</AgxLabel>
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="listingsEmail"
                label="Listings Email"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={listingsEmail || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    listingsEmail: value,
                  }))
                }
              />
            </AgxRow>
            <AgxRow>
              <AgxPhoneNumberInput
                id="phoneNumber"
                label="Phone Number"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={listingsPhone || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    listingsPhone: value,
                  }))
                }
              />
            </AgxRow>
          </AgxColumn>
          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Address</AgxLabel>
            </AgxRow>
            <AgxRow>
              <AgxTextInput
                id="streetAddress"
                label="Street Address"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={streetAddress || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      streetAddress: value,
                    },
                  }))
                }
              />
            </AgxRow>
            <AgxRow veryLargeGap>
              <AgxTextInput
                id="suburb"
                label="Suburb"
                stretch
                noOptionalLabel
                defaultValue={suburb || ''}
                parentControlValue={true}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      suburb: value,
                    },
                  }))
                }
              />

              <AgxSelect
                id="state"
                label="State"
                hideOptionalLabel
                options={allStateOptions}
                defaultValue={addressStateDefaultValue}
                onValueChanged={({ value }: { value: string }) => {
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      state: value,
                    },
                  }))
                }}
              />

              <AgxTextInput
                id="postcode"
                label="Postcode"
                stretch
                noOptionalLabel
                parentControlValue={true}
                defaultValue={postalCode || ''}
                onInputValueChange={({ value }: { value: string }) =>
                  setAgencyInformation((previousState: AgencyDetails) => ({
                    ...previousState,
                    address: {
                      ...previousState.address,
                      postalCode: value,
                    },
                  }))
                }
              />
            </AgxRow>
          </AgxColumn>

          <AgxColumn veryLargeGap extraClasses="container50Percent">
            <AgxRow extraClasses="borderBottomContainer">
              <AgxLabel large>Extras</AgxLabel>
            </AgxRow>
            <AgxTextArea
              id="instructionsConditions"
              label="Reassignment Clause"
              parentControlValue
              defaultValue={instructionsConditions ?? ''}
              onInputValueChange={({ value }: { value: string }) =>
                setAgencyInformation((previousState: AgencyDetails) => ({
                  ...previousState,
                  instructionsConditions: value,
                }))
              }
              rows={6}
              maxLength={1100}
              showCharCount
              noOptionalLabel
              stretch
            />
          </AgxColumn>
        </AgxColumn>
      </ContentLayout>
    </PageLayout>
  )
}

export default AgencyDetailsPage

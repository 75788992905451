import { AustralianStateNumericMap } from "@urbanx/agx-ui-components"

export enum PackageItemType {
  PRICE = 'Price',
  COMPLIMENTARY = 'Complimentary',
  AGENT_TO_FILL = 'Agent to fill',
}

export interface Agency {
  id: string
  name: string
  logoUrl: string
  states: AustralianStateNumericMap[]
  backgroundColor: string
}

export interface UploadedDocument {
  documentId: string
  containerFilePath: string
  publicPath: string
  fileName: string
  fileSizeInBytes: number
  documentType: string
}

export interface IBranding {
  id: number
  backgroundColor: string
  backgroundTextColor: string
  accentColor: string
  buttonTextColor: string
  inboxBackgroundColor: string
  inboxBackgroundColorOpacity: number
  sidebarBackgroundColor: string
  sidebarBackgroundColorOpacity: number
  logo?: UploadedDocument
}

export class Branding implements IBranding {
  id: number
  backgroundColor: string
  backgroundTextColor: string
  accentColor: string
  buttonTextColor: string
  inboxBackgroundColor: string
  inboxBackgroundColorOpacity: number
  sidebarBackgroundColor: string
  sidebarBackgroundColorOpacity: number
  logo?: UploadedDocument
  constructor() {
    this.id = 0
    this.backgroundColor = '#FFFFFF'
    this.backgroundTextColor = '#000000'
    this.accentColor = '#000000'
    this.buttonTextColor = '#000000'
    this.inboxBackgroundColor = '#FFFFFF'
    this.inboxBackgroundColorOpacity = 0
    this.sidebarBackgroundColor = '#FFFFFF'
    this.sidebarBackgroundColorOpacity = 0
    this.logo = undefined
  }
}

export interface Team {
  id: string
  name: string
  members?: string[],
  photoUri?: string | null
}

export enum AgentRole {
  Director = 'director',
  SalesAgent = 'salesagent',
  Admin = 'admin',
}

export enum LicenceType {
  None,
  RealEstateCertificate,
  RealEstateLicence,
  Class1,
  Class2
}

export interface ITeamMember {
  id: string
  firstName: string
  lastName: string
  title: string
  email: string
  phone: string
  role: AgentRole
  teams: string[]
  photo?: UploadedDocument
  agentCompanyDetails: CompanyDetails
  agencyId: string
  useLicenseeDetails: boolean
  licenceType: LicenceType
  softDeleted: boolean
  emailInvitationSent: boolean
}

export class TeamMember implements ITeamMember {
  id: string
  firstName: string
  lastName: string
  title: string
  email: string
  phone: string
  role: AgentRole
  teams: string[]
  photo?: UploadedDocument | undefined
  agentCompanyDetails: CompanyDetails
  agencyId: string
  useLicenseeDetails: boolean
  licenceType: LicenceType
  instructionsConditions?: string
  emailInvitationSent: boolean
  softDeleted: boolean

  constructor() {
    this.id = ''
    this.firstName = ''
    this.lastName = ''
    this.title = ''
    this.email = ''
    this.phone = ''
    this.role = AgentRole.SalesAgent
    this.teams = []
    this.photo = undefined
    this.agentCompanyDetails = new CompanyDetails()
    this.agencyId = ''
    this.useLicenseeDetails = false
    this.licenceType = LicenceType.None
    this.emailInvitationSent = false
    this.softDeleted = false
    this.instructionsConditions = ''
  }
}

export interface MarketingPackage {
  id: string
  name: string
  includeRealEstateListing: boolean
  includeDomainListing: boolean
  displayItemisedPricingOnPdf: boolean
  items: string[]
  addOns: string[]
  displayName: string
  assignedAgentIds: string[]
  specialConditions?: string
}

export interface PackageItem extends Validation {
  productName: string
  itemType: string
  pricePerUnit?: number
  supplier?: string
  stockType?: string
  id?: string
}

export interface LicenceDetails {
  licenceNumber: string
  expiryDate?: string | null
  state?: string
  agreements?: number[] | null
  uuid?: string
}
export interface ICompanyDetails {
  licenseeName: string
  abn: string
  acn: string
  licences: LicenceDetails[]
}
export class CompanyDetails implements ICompanyDetails {
  licenseeName: string
  abn: string
  acn: string
  state: number | null
  licences: LicenceDetails[]
  constructor() {
    this.abn = ''
    this.acn = ''
    this.licenseeName = ''
    this.state = null
    this.licences = []
  }
}

export interface LicenseeDetails {
  useUrbanXDetails: boolean
  listingsEmail: string
  contactEmail: string
  phoneNumber: string
}

export interface Validation {
  errors?: string[]
}

interface AgentNameType {
  firstName: string
  middleName: string | null
  lastName: string
  title: string | null
}

export interface agentTypes {
  id: string
  name: AgentNameType | null
  hasRealEstateAgentLicense: boolean
}

export interface IUrbanXDetails {
  id?: string
  tradingName?: string
  listingsEmail?: string
  listingsPhoneNumber?: string
  contractsEmail?: string
  address?: Address
  companyDetails: CompanyDetails
}

export interface IAgencyDetails {
  id?: string
  name?: string
  tradingName?: string
  companyDetails: CompanyDetails
  listingsEmail?: string
  listingsPhone?: string
  contractsEmail?: string
  privacyPolicyLink?: string
  instructionsConditions?: string
  address?: Address
  agentIds: string[]
  agencyStates?: number[]
}

export interface Address {
  lotNumber?: string
  streetAddress?: string
  suburb?: string
  state?: string
  postalCode?: string
}

export class UrbanXDetails implements IUrbanXDetails {
  tradingName?: string
  listingsEmail?: string
  listingsPhoneNumber?: string
  contractsEmail?: string
  address?: Address
  companyDetails: CompanyDetails
  constructor() {
    this.companyDetails = new CompanyDetails()
  }
}
export class AgencyDetails implements IAgencyDetails {
  id?: string
  name?: string
  tradingName?: string
  companyDetails: CompanyDetails
  listingsEmail?: string
  listingsPhone?: string
  contractsEmail?: string
  address?: Address
  privacyPolicyLink?: string
  instructionsConditions?: string
  agentIds: string[]
  // agencyStates?: number[]
  constructor() {
    this.companyDetails = new CompanyDetails()
    this.agentIds = []
  }
}

export interface Permission {
  leadAgentId?: string
  secondAgentId?: string
  createdByUserId?: string
  assignedAgentIds?: string[]
  campaignId?: string
}
import { LicenceDetails } from 'types/agency'
import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import AgencyService from '../../agenciesService'

interface UpdateUrbanXDetailsRequest extends ApiRequestBody {
  CompanyDetails?: {
    LicenseeName?: string
    Abn?: string
    Acn?: string
    Licences: LicenceDetails[]
  }
  ListingsEmail?: string
  ListingsPhoneNumber?: string
  ContractsEmail?: string
  TradingName?: string
  Address?: {
    LotNumber?: string
    StreetAddress?: string
    Suburb?: string
    State?: string
    PostalCode?: string
  }
}

const UpdateUrbanXDetails = async (
  requestBody: UpdateUrbanXDetailsRequest
): Promise<void> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new AgencyService(token)

  if (!service) return

  await service.agencyService.put('UpdateUrbanXDetails', {
    ...requestProperties,
  })
}

export default UpdateUrbanXDetails

import { ApiRequestBody } from '../../../interfaces/ApiRequestBody'
import CampaignsService from '../../campaignsService'

interface BeginCampaign extends ApiRequestBody {
  AgencyId: string
  State: number
}

interface BeginCampaignResponse {
  campaingId: string
}

const beginCampaign = async (
  requestBody: BeginCampaign
): Promise<BeginCampaignResponse | undefined> => {
  const { getAuthToken, ...requestProperties } = requestBody

  const token = await getAuthToken()
  if (!token) return

  const service = new CampaignsService(token)

  if (!service) return

  const result = await service.campaignsService.post('StaffBeginCampaign', {
    ...requestProperties,
  })
  return { campaingId: result.data} as BeginCampaignResponse
}

export default beginCampaign